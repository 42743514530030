import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls'
Vue.use(Vuex)

const ls = new SecureLS({encodingType: 'aes', isCompression: true, encryptionSecret: `${navigator.userAgent}`});

const state = {
	sidebarShow: 'responsive',
	sidebarMinimize: false,
	token: null,
	perm: null,
	user_id: null,
	expires: '',
}

const mutations = {
	toggleSidebarDesktop (state) {
		const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
		state.sidebarShow = sidebarOpened ? false : 'responsive'
	},
	toggleSidebarMobile (state) {
		const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
		state.sidebarShow = sidebarClosed ? true : 'responsive'
	},
	set (state, [variable, value]) {
		state[variable] = value
	},
	set_token(state, token){
		state.token = token;
	},
	set_perm(state, perm){
		state.perm = perm;
	},
	set_user_id(state, user_id){
		state.user_id = user_id;
	},
	set_expires(state, expires){
		state.expires = expires;
	},
	reset(state) {
		state.token = null, state.perm = null, state.user_id = null, state.expires = null;
	},
}

export default new Vuex.Store({
	state,
	mutations,
	plugins: [createPersistedState({
		storage: {
			getItem: key => ls.get(key),
			setItem: (key, value) => ls.set(key, value),
			removeItem: key => ls.remove(key)
		}
	})],
})
import Vue from 'vue'
import Router from 'vue-router'

const TheContainer = () => import('@/containers/TheContainer')
const Dashboard = () => import('@/views/Dashboard')
const SignIn = () => import('@/views/SignIn')
const SignUp = () => import('@/views/SignUp')
const ForgotPassword = () => import('@/views/ForgotPassword')
const Page403 = () => import('@/views/Page403')
const Page404 = () => import('@/views/Page404')
const AddDataSource = () => import('@/views/AddDataSource')
const GetDataSources = () => import('@/views/GetDataSources')
const GetDataSourceDetails = () => import('@/views/GetDataSourceDetails')
const GetDataSourceLastData = () => import('@/views/GetDataSourceLastData')
const UpdateDataSource = () => import('@/views/UpdateDataSource')
const AddInputData = () => import('@/views/AddInputData')
const GetInputData = () => import('@/views/GetInputData')
const GetInputDataDetails = () => import('@/views/GetInputDataDetails')
const UpdateInputData = () => import('@/views/UpdateInputData')
const AddProcesses = () => import('@/views/AddProcesses')
const GetProcesses = () => import('@/views/GetProcesses')
const GetProcessesDetails = () => import('@/views/GetProcessesDetails')
const UpdateProcesses = () => import('@/views/UpdateProcesses')
const AddTrigger = () => import('@/views/AddTrigger')
const GetTriggers = () => import('@/views/GetTriggers')
const GetTriggerDetails = () => import('@/views/GetTriggerDetails')
const UpdateTrigger = () => import('@/views/UpdateTrigger')
const AddUser = () => import('@/views/AddUser')
const GetUsers = () => import('@/views/GetUsers')
const Profile = () => import('@/views/Profile')
const UpdateUser = () => import('@/views/UpdateUser')
const AddToken = () => import('@/views/AddToken')
const GetTokens = () => import('@/views/GetTokens')
const UpdateToken = () => import('@/views/UpdateToken')
const GetOutputData = () => import('@/views/GetOutputData')
const GetDataSourceLogs = () => import('@/views/GetDataSourceLogs')
const GetDataSourceLogsDetails = () => import('@/views/GetDataSourceLogsDetails')
const AddTelegramAccount = () => import('@/views/AddTelegramAccount')
const GetTelegramAccounts = () => import('@/views/GetTelegramAccounts')
const GetTelegramAccountDetails = () => import('@/views/GetTelegramAccountDetails')
const UpdateTelegramAccount = () => import('@/views/UpdateTelegramAccount')
const GetUserGroups = () => import('@/views/GetUserGroups')
const AddUserGroup = () => import('@/views/AddUserGroup')
const UpdateUserGroup = () => import('@/views/UpdateUserGroup')
const GetGroupUsers = () => import('@/views/GetGroupUsers')
const AddUserToUserGroup = () => import('@/views/AddUserToUserGroup')
const GetSpecificData = () => import('@/views/GetSpecificData')
const AddSpecificData = () => import('@/views/AddSpecificData')
const UpdateSpecificData = () => import('@/views/UpdateSpecificData')
const UpdatePermissions = () => import('@/views/UpdatePermissions')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
	{ path: '*', component: Page404 },
    {
      	path: '/',
      	redirect: '/signin',
      	component: TheContainer,
		children: [
			{
				path: 'dashboard/:order_by/:order_type',
				name: 'Dashboard',
				component: Dashboard,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/source/add',
				name: 'Add Data Source',
				component: AddDataSource,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/sources',
				name: 'Data Sources',
				component: GetDataSources,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/source/:id/details',
				name: 'Data Sources Details',
				component: GetDataSourceDetails,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/source/:id/last',
				name: 'Last Data',
				component: GetDataSourceLastData,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/source/:id/update',
				name: 'Update Data Source',
				component: UpdateDataSource,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/source/logs',
				name: 'Data Sources Logs',
				component: GetDataSourceLogs,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/source/logs/:id/details',
				name: 'Data Source Logs Details',
				component: GetDataSourceLogsDetails,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/input/add',
				name: 'Add Input Data',
				component: AddInputData,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/input',
				name: 'Input Data',
				component: GetInputData,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/input/:id/details',
				name: 'Input Data Details',
				component: GetInputDataDetails,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/input/:id/update',
				name: 'Update Input Data',
				component: UpdateInputData,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/proccesses/add',
				name: 'Add Data Processes',
				component: AddProcesses,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/proccesses',
				name: 'Data Processes',
				component: GetProcesses,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/proccesses/:id/details',
				name: 'Data Processes Details',
				component: GetProcessesDetails,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/proccesses/:id/update',
				name: 'Update Data Processes',
				component: UpdateProcesses,
				meta: { requiresAuth: true },
			},
			{
				path: 'trigger/add',
				name: 'Add Trigger',
				component: AddTrigger,
				meta: { requiresAuth: true },
			},
			{
				path: 'triggers',
				name: 'Triggers',
				component: GetTriggers,
				meta: { requiresAuth: true },
			},
			{
				path: 'trigger/:id/details',
				name: 'Triggers Details',
				component: GetTriggerDetails,
				meta: { requiresAuth: true },
			},
			{
				path: 'trigger/:id/update',
				name: 'Update Trigger',
				component: UpdateTrigger,
				meta: { requiresAuth: true },
			},
			{
				path: 'user/add',
				name: 'Add User',
				component: AddUser,
				meta: { requiresAuth: true },
			},
			{
				path: 'users',
				name: 'Users',
				component: GetUsers,
				meta: { requiresAuth: true },
			},
			{
				path: 'profile/:id',
				name: 'User Profile',
				component: Profile,
				meta: { requiresAuth: true },
			},
			{
				path: 'user/:id/update',
				name: 'Update Users',
				component: UpdateUser,
				meta: { requiresAuth: true },
			},
			{
				path: 'token/add',
				name: 'Add Token',
				component: AddToken,
				meta: { requiresAuth: true },
			},
			{
				path: 'tokens',
				name: 'Tokens',
				component: GetTokens,
				meta: { requiresAuth: true },
			},
			{
				path: 'token/:id/update',
				name: 'Update Token',
				component: UpdateToken,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/output',
				name: 'Output Data',
				component: GetOutputData,
				meta: { requiresAuth: true },
			},
			{
				path: 'telegram/account/add',
				name: 'Add Telegram Account',
				component: AddTelegramAccount,
				meta: { requiresAuth: true },
			},
			{
				path: 'telegram/accounts',
				name: 'Telegram Accounts',
				component: GetTelegramAccounts,
				meta: { requiresAuth: true },
			},
			{
				path: 'telegram/account/:id/details',
				name: 'Telegram Account Details',
				component: GetTelegramAccountDetails,
				meta: { requiresAuth: true },
			},
			{
				path: 'telegram/account/:id/update',
				name: 'Update Telegram Account',
				component: UpdateTelegramAccount,
				meta: { requiresAuth: true },
			},
			{
				path: 'groups',
				name: 'User Groups',
				component: GetUserGroups,
				meta: { requiresAuth: true },
			},
			{
				path: 'group/add',
				name: 'Add User Group',
				component: AddUserGroup,
				meta: { requiresAuth: true },
			},
			{
				path: 'group/:id/update',
				name: 'Update User Group',
				component: UpdateUserGroup,
				meta: { requiresAuth: true },
			},
			{
				path: 'group/:id/users',
				name: 'User Group Users',
				component: GetGroupUsers,
				meta: { requiresAuth: true },
			},
			{
				path: 'group/:id/user/add',
				name: 'Add User to User Group',
				component: AddUserToUserGroup,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/custom',
				name: 'Specific Data',
				component: GetSpecificData,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/custom/add',
				name: 'Add Specific Data',
				component: AddSpecificData,
				meta: { requiresAuth: true },
			},
			{
				path: 'data/custom/:id/update',
				name: 'Update Specific Data',
				component: UpdateSpecificData,
				meta: { requiresAuth: true },
			},
			{
				path: 'group/:id/permissions',
				name: 'Permissions',
				component: UpdatePermissions,
				meta: { requiresAuth: true },
			},

		]
	},
	{
		path: '/signin',
		component: SignIn
	},
	{
		path: '/signup',
		component: SignUp
	},
	{
		path: '/password/reset',
		component: ForgotPassword
	},
	{
		path: '/403',
		component: Page403,
	},
  ]
}


<template>
    <div>
        <notifications position="top left" group="foo" />
        <router-view></router-view>
    </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  @import 'assets/scss/style';
</style>

import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from 'vue-notification'
import VueJalaliMoment from 'vue-jalali-moment'
import 'vue-search-select/dist/VueSearchSelect.css'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
Vue.use(VueAxios, axios)
axios.defaults.baseURL = 'https://apexprobox.com/api-v1/'
axios.interceptors.request.use(function (config) {
  config.headers.Authorization =  store.state.token;
  return config;
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
      if(store.state.token == null){
          next({
              path: '/signin',
              query: { redirect: to.fullPath }
          })
      } else if(new Date() > new Date(store.state.expires)){
          next({
              path: '/signin',
              query: { redirect: to.fullPath }
          })
      }
      else{
          next();
      }
  } else {
      next()
  }
})
Vue.use(Notifications)
Vue.use(VueJalaliMoment)
Vue.use(VuePersianDatetimePicker, {name: 'date-picker', props: { editable: true }});

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
